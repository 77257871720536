import { combineReducers } from "redux";
import homeReducer from "./homeReducer";
import apiKeyReducer from "./apiKeyReducer";
import productReducer from "./productReducer";
import testimonialReducer from "./testimonialReducer";
import hardwareReducer from "./hardwareReducer";
import showroomReducer from "./showroomReducer";
import aboutReducer from "./aboutReducer";

const reducer = combineReducers({
  homeReducer,
  apiKeyReducer,
  hardwareReducer,
  testimonialReducer,
  productReducer,
  aboutReducer,
  showroomReducer,
});

export default reducer;
