import React from "react";
import { Icon } from "@iconify/react";

const TabList = ({video, setVideoTab, title, src, icon}) => {
  return (
    <li
      className={`px-1 cursor-pointer font-bold duration-200 transition-all flex align-middle ${
        video === src ? "" : "opacity-50"
      }`}
      onClick={() => setVideoTab(src)}
    >
      <Icon icon={icon} className="text-2xl mr-3" />
      <span>{title}</span>
      {video === src &&(<Icon icon="ic:round-arrow-forward" className="text-2xl mr-3 text-primary ml-auto" />)}
    </li>
  );
};

export default TabList;
