const initialStateApi = {
  key: null,
};

const setApiKey = (state = initialStateApi, action) => {
  if (action.type === "SET_KEY") {
    return {
      ...state,
      key: action.payload,
    };
  }

  return state;
};

export default setApiKey;
