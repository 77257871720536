import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImgProfile3 } from "../../assets/images";
import { Area, Footer, Hero, Navbar, Profile } from "../../components";
import { getUri } from "../../configs";
import { GetAboutBanner, GetAboutProfile } from "../../configs/Redux/actions/aboutAction";

const AboutPage = () => {
  const dispacth = useDispatch();
  const { dataAboutBanner, dataAboutProfile } = useSelector((state) => state.aboutReducer);

  useEffect(() => {
    if (dataAboutBanner === null) {
      dispacth(GetAboutBanner());
    }
    if (dataAboutProfile === null) {
      dispacth(GetAboutProfile());
    }
  }, [dataAboutBanner, dataAboutProfile, dispacth]);

  return (
    <div>
      <Navbar />
      <main className="">
        <Hero
          variant="alternatif"
          img={dataAboutBanner === null ? null : getUri() + "/images/" + dataAboutBanner.image}
          title={dataAboutBanner === null ? null : dataAboutBanner.title}
          button="nullable"
        />
        {dataAboutProfile === null ? (
          <Profile
            title={"..."}
            description="..."
            image={"..."}
            button={false}
            className="my-32 md:pt-32 mobile:mx-5"
          />
        ) : (
          dataAboutProfile.map((res) => {
            return (
              <Profile
                title={res.title}
                description={res.description}
                image={getUri() + '/images/' + res.image}
                button={false}
                className="my-32 md:pt-32 mobile:mx-5"
              />
            );
          })
        )}
        <Area />
      </main>
      <Footer />
    </div>
  );
};

export default AboutPage;
