import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Contact, Footer, Hero, Navbar, Pricings, Profiles } from "../../components";
import { getUri } from "../../configs";
import { GetHomepagePrice } from "../../configs/Redux/actions/homeAction";
import { GetProductBanner, GetProductProfile } from "../../configs/Redux/actions/productAction";

const ProductPage = () => {
  const { dataHomePrice, rowHomePrice } = useSelector((state) => state.homeReducer);
  const { dataProductBanner, rowProductBanner, dataProductProfile, rowProductProfile } = useSelector(
    (state) => state.productReducer
  );
  const dispatch = useDispatch();
  const baseurl = getUri();

  useEffect(() => {
    dispatch(GetHomepagePrice());
    dispatch(GetProductBanner());
    dispatch(GetProductProfile());
  }, [dispatch]);

  let title = rowProductBanner < 1 ? null : dataProductBanner.title.split("~");
  let image = rowProductBanner < 1 ? null : baseurl + "/images/" + dataProductBanner.image;

  getUri();

  return (
    <div className="lg:bg-wallpaper xl:bg-transparent">
      <Navbar variant="gradient" />
      <main className="">
        <Hero variant="secondary" title={title} img={image} button={"nullable"} />
        <Profiles className="mb-10" data={rowProductProfile > 0 ? dataProductProfile : null} />
        <Pricings data={rowHomePrice > 0 ? dataHomePrice : null} />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default ProductPage;
