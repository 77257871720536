import React from "react";
import { Contact, Footer, Navbar } from "../../components";

const ContactPage = () => {
  return (
    <div className="">
      <Navbar />
      <main className="">
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
