import React from "react";
import { Container } from "../../Modules";
import Product from "../Product";

const Products = ({ className, data }) => {
  return (
    <div className={className}>
      <Container className="grid md:grid-cols-4 xl:px-0 mobile:grid-cols-2">
        {data === null ? (
          <>
            <Product variant="skeleton" />
            <Product variant="skeleton" />
            <Product variant="skeleton" />
            <Product variant="skeleton" />
            <Product variant="skeleton" />
            <Product variant="skeleton" />
          </>
        ) : (
          <>
            {data.map((val) => {
              return <Product name={val.name} img={val.image} price={val.price} key={'product' + val.id + Math.random(99,999)} />;
            })}
          </>
        )}
      </Container>
    </div>
  );
};

export default Products;
