const initialStateApi = {
    data: null,
    row_data : 0
  };
  
  const testimonialReducer = (state = initialStateApi, action) => {
    if (action.type === "SET_DATA_TESTIMONIAL") {
      return {
        ...state,
        data: action.payload,
        row_data: action.row,
      };
    }
  
    return state;
  };
  
  export default testimonialReducer;
  