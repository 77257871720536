import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer, Hero, Navbar, Tabs } from "../../components";
import { getUri } from "../../configs";
import { GetShowroomBanner, GetShowroomVideo } from "../../configs/Redux/actions/showroomAction";

const ShowroomPage = () => {
  const { dataShowroomBanner, dataShowroomVideo } = useSelector((state) => state.showroomReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataShowroomBanner === null) {
      dispatch(GetShowroomBanner());
    }
    if (dataShowroomVideo === null) {
      dispatch(GetShowroomVideo());
    }
  }, [dataShowroomBanner, dataShowroomVideo, dispatch]);

  return (
    <div className="lg:bg-wallpaper xl:bg-transparent">
      <Navbar />
      <main className="">
        <Hero
          img={dataShowroomBanner === null ? null : getUri() + "/images/" + dataShowroomBanner.image}
          variant="normal"
          title={dataShowroomBanner === null ? null : dataShowroomBanner.title.split(",")}
          button="nullable"
        />
        <Tabs />
      </main>
      <Footer />
    </div>
  );
};

export default ShowroomPage;
