import React, { useEffect } from "react";
import { Footer, Navbar } from "../../../components";
import MainPrivacy from "../../../components/Molecules/MainPrivacy";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar/>
      <MainPrivacy variant="privacy" title="Terms of Privacy"/>
      <Footer className="mt-48"/>
    </div>
  );
};

export default PrivacyPage;
