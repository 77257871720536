/* eslint-disable no-unused-expressions */
import "./App.css";
import Router from "../configs/Router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
      <Router />
  );
}

export default App;
