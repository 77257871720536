import React from "react";
import { useNavigate } from "react-router-dom";
import { ImgContact2, LogoMini } from "../../assets/images";
import { Button, Input } from "../../components";

const LoginPage = () => {
  const history = useNavigate();
  return (
    <div className="mobile:h-full md:h-screen w-screen">
      <div className="flex">
        <div className="bg-secondary flex content-center md:h-screen mobile:h-full w-7/12 mobile:hidden">
          <img src={ImgContact2} alt=""/>
        </div>
        <div className="flex content-center mobile:h-screen md:h-screen md:w-5/12">
          <div className="mx-auto h-full md:w-8/12 mobile:w-10/12 flex flex-col">
            <div className="header my-12 mt-20 space-y-4 text-center">
              <img src={LogoMini} className="mx-auto cursor-pointer" alt=""  onClick={() => history('/')} />
              <h1 className="text-3xl font-semibold">
                <span className="text-primary">Login</span> Page
              </h1>
              <p className="text-gray-400">Welcome user, You can login in this page or register in another page</p>
            </div>
            <div className="flex flex-col space-y-4">
              <Input label="Email" type="email" />
              <Input label="Password" type="password" />
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Remember me</p>
                <p className="text-sm text-primary cursor-pointer font-medium">Forgot Password?</p>
              </div>
              <Button variant="orange" className="rounded-md" onClick={() => history('/hardware')}>
                Login
              </Button>
            </div>
            <div className="mobile:mb-16 mb-4 mt-auto text-center">
              <p>
                Dou you have account ? <span className="text-primary cursor-pointer font-medium" onClick={() => history('/auth/register')}>Sign Up</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
