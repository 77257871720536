import React from "react";
import { Container } from "../../Modules";
import Advantage from "../Advantage";

const Advantages = () => {
  return (
    <div className="my-40">
      <Container className="text-center">
        <div className="mx-3">
          <h4 className="font-medium text-5xl mobile:text-3xl">Features</h4>
          <p className="md:text-xl mobile:text-md text-gray-400 pt-3 md:pb-20 md:px-32">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam quam dolorum quae porro voluptatibus
            dignissimos qui necessitatibus soluta sequi beatae!
          </p>
          <div className="grid md:grid-cols-3 gap-6">
            <Advantage
              title="Additional Experience"
              description="Rasakan pengalaman dan kemudahan dari software-software sebelumnya"
              variant="primary"
            />
            <Advantage
              title="Additional Experience"
              description="Rasakan pengalaman dan kemudahan dari software-software sebelumnya"
            />
            <Advantage
              title="Additional Experience"
              description="Rasakan pengalaman dan kemudahan dari software-software sebelumnya"
            />
            <Advantage
              title="Additional Experience"
              description="Rasakan pengalaman dan kemudahan dari software-software sebelumnya"
            />
            <Advantage
              title="Additional Experience"
              description="Rasakan pengalaman dan kemudahan dari software-software sebelumnya"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Advantages;
