import React, { useRef } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import Slider from "react-slick";
import { ImgPricing } from "../../../assets/images";
import { Container } from "../../Modules";
import Pricing from "../Pricing";

const Pricings = ({ data }) => {
  let settings = {
    infinite: true,
    autoplaySpeed: 2500,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const sliderRef = useRef();
  return (
    <div className="bg-wallpaper py-28">
      <Container className="mobile:mx-3">
        <h2 className="text-2xl font-semibold">Harga Paket</h2>
        <div className="flex mobile:flex-col">
          <div className="w-4/12 mobile:w-full">
            <h4 className="text-lg mt-6">Otomatiskan bisnismu sekarang dengan berlangganan atau coba gratis</h4>
            <div className="py-12 space-x-12">
              <button>
                <ArrowLeft size={46} className="text-primary" onClick={() => sliderRef.current.slickPrev()} />
              </button>
              <button>
                <ArrowRight size={46} className="text-primary" onClick={() => sliderRef.current.slickNext()} />
              </button>
            </div>
            <img src={ImgPricing} className="mobile:hidden" alt="" />
          </div>
          {data === null ? (
            <Slider ref={sliderRef} {...settings} className="mobile:w-full w-8/12 md:pt-6 flex">
              <Pricing variant="skeleton" />
              <Pricing variant="skeleton" />
              <Pricing variant="skeleton" />
            </Slider>
          ) : (
            <Slider ref={sliderRef} {...settings} className="mobile:w-full w-8/12 md:pt-6 flex">
              {data.map((value) => {
                return (
                  <Pricing
                  key={data.id + 1399 + Math.random(99, 999)}
                    price={value.price}
                    tag={value.type}
                    description={value.description}
                    features={value.features}
                    variant={value.pinned ? "pricing-active" : ""}
                  />
                );
              })}
            </Slider>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Pricings;
