import React, {  useState } from "react";
import { IconMac, IconWindow } from "../../../assets/icons";
import { ImgItem, ImgItem2 } from "../../../assets/images";
import { Container } from "../../Modules";

const HeroProduct = () => {
  const [postImage, setPostImage] = useState("first");
  const [classImage, setClassImage] = useState(true);

  const classImg = classImage ? "opacity-100 transition-all duration-1000" : "opacity-0 transition-all duration-1000";

  const image = postImage === "first" ? ImgItem : ImgItem2;
  return (
    <div>
      <Container className="pt-10 mobile:mx-5">
        <div className="md:w-6/12 md:my-12 mobile:my-6">
          <h1 className="md:text-6xl mobile:text-3xl font-semibold">Boost your design workflow with OLMO</h1>
        </div>
        <div className="flex mobile:flex-col-reverse">
          <div className="md:w-3/12 mobile:w-12/12 space-y-4">
            <p className="text-xl">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <div className="flex px-5 py-8 bg-white text-dark shadow-custom rounded-md">
              <div className="w-2/12 my-auto">
                <img src={IconWindow} alt="" />
              </div>
              <div className="ml-5">
                <p className="font-bold">Download for Windows</p>
                <p className="text-gray-400">try 30 days free trial</p>
              </div>
            </div>
            <div className="flex px-5 py-8 bg-white text-dark shadow-custom rounded-md">
              <div className="w-2/12 my-auto">
                <img src={IconMac} alt="" />
              </div>
              <div className="ml-5">
                <p className="font-bold">Download for Mac</p>
                <p className="text-gray-400">try 30 days free trial</p>
              </div>
            </div>
            <p className="font-medium">Version 2.10.074 | Free 30 days trial</p>
          </div>
          <div className="md:w-3/12 mobile:w-0/12"></div>
          <div className="md:w-6/12 mobile:w-12/12 md:-mt-40">
            <img src={image} alt="" className={`${classImg} w-11/12`} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroProduct;
