import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormatRupiah, getUri } from "../../../configs";
import { Button } from "../../Atoms";

const Product = ({ img, name, price, variant }) => {
  const [classAdd, setClassAdd] = useState(false);
  const pickedClass = classAdd ? " -translate-y-24 opacity-0 transition-all duration-1000" : "";
  const pickedChildClass = classAdd ? "-translate-y-10 transition-all duration-1000" : "";
  const history = useNavigate();
  const img1 = Array.isArray(img) ? img[0] : img;
  const img2 = Array.isArray(img) ? img[1] : img;

  return (
    <div className="space-y-2 text-center content-center items-center flex flex-col p-4">
      {variant === "skeleton" ? (
        <div className="w-full h-72 rounded-xl animate-pulse bg-slate-300"></div>
      ) : (
        <div onMouseOver={() => setClassAdd(true)} onMouseLeave={() => setClassAdd(false)}>
          <div className="absolute -z-10">
            <div className="bg-light md:h-full md:w-full flex content-center absolute rounded-3xl mobile:h-[150px]">
              <div
                className={`mt-auto ml-3 mb-1 bg-primary text-white mobile:p-1 mobile:px-2 md:py-4 md:pl-6 md:pr-16 ${pickedChildClass}`}
              >
                <h1 className="font-semibold text-md text-left">{name}</h1>
                <h2 className="text-sm text-left">{price ? FormatRupiah(price) : "Rp. 2.000,00"}</h2>
              </div>
            </div>
            <img
              src={getUri() + '/images/' + img1}
              alt="Img Items 1"
              className="rounded-3xl md:h-full mobile:h-[150px]"
            />
          </div>
          <img
            src={getUri() + '/images/' + img2}
            alt="Img Items 1"
            className={`bg-wallpaper rounded-3xl ${pickedClass}`}
          />
        </div>
      )}
      {variant === "skeleton" ? (
        <div className="h-6 rounded-full w-40 animate-pulse bg-slate-400"></div>
      ) : (
        <h1 className="text-lg font-medium">{name}</h1>
      )}
      <div>
        {variant === "skeleton" ? (
          <div className="h-10 w-36 rounded-full animate-pulse bg-slate-400"></div>
        ) : (
          <Button
            variant="orange-gradient"
            className="font-medium md:px-8 mobile:text-sm"
            onClick={() => history("/product/detail")}
          >
            Lihat Detail
          </Button>
        )}
      </div>
    </div>
  );
};

export default Product;
