import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandLogo, ImgHero3 } from "../../assets/images";
import { Contact, Footer, Hero, Navbar, Products } from "../../components";
import { getUri } from "../../configs";
import { GetHardwareBanner, GetHardwareProduct } from "../../configs/Redux/actions/hardwareAction";

const HardwarePage = () => {
  const {
    dataHardwareBanner,
    dataHardwareProduct,
  } = useSelector((state) => state.hardwareReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if(dataHardwareProduct === null){
      dispatch(GetHardwareProduct());
    }
    if(dataHardwareBanner === null){
      dispatch(GetHardwareBanner());
    }
  }, [dataHardwareBanner, dataHardwareProduct, dispatch]);

  return (
    <div className="">
      <Navbar variant="dark" />
      <main className="">
        <Hero
          variant="dark"
          button={"nullable"}
          title={dataHardwareBanner === null ? null : dataHardwareBanner.title}
          img={dataHardwareBanner === null ? null : getUri() + '/images/' + dataHardwareBanner.image}
          logo={dataHardwareBanner === null ? "skeleton" : getUri() + '/images/' + dataHardwareBanner.additional}
        />
        <Products className="my-36 py-28" data={dataHardwareProduct} />
        <Contact variant="secondary" />
      </main>
      <Footer />
    </div>
  );
};

export default HardwarePage;
