import React from "react";
import { Card } from "../../Modules";

const Testimonial = ({ name, status, statement, img }) => {
  return (
    <div variant="zoom-in" duration={2000} className={`2xl:ml-[27rem] xl:ml-[18.8rem] shadow-custom ${name ? '' : 'animate-pulse'}`}>
      <Card variant="testimonial" className="2xl:-mr-[25rem] xl:-mr-[17.8rem]">
        <div className="w-4/12 mobile:mt-3 mobile:mr-3">
          {img && <img src={img} alt="" className="rounded bg-gray-400 h-52 object-cover w-full" />}
          {!img && <div className="rounded bg-gray-400 h-full w-full"></div>}
        </div>
        <div className="w-10/12 space-y-3 ml-8">
          {name && <h2 className="text-xl font-bold">{name}</h2>}
          {!name && <div className="h-4 bg-gray-500 rounded-md w-4/12"></div>}
          {status && <h2 className="text-md font-bold text-primary">{status}</h2>}
          {!status && <div className="h-4 bg-gray-500 rounded-md w-3/12"></div>}
          {statement && <p className="text-md leading-7">"{statement}"</p>}
          {!statement && (
            <div className="grid grid-cols-4 gap-4 py-4">
              <div className="h-3 bg-gray-400 rounded col-span-3"></div>
              <div className="h-3 bg-gray-400 rounded col-span-1"></div>
              <div className="h-3 bg-gray-400 rounded col-span-1"></div>
              <div className="h-3 bg-gray-400 rounded col-span-3"></div>
              <div className="h-3 bg-gray-400 rounded col-span-2"></div>
              <div className="h-3 bg-gray-400 rounded col-span-2"></div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Testimonial;
