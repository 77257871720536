import React from "react";
import {
  ImgProduct,
  ImgProduct2,
  ImgProduct3,
  ImgProduct4,
  ImgProduct5,
  ImgProduct6,
  ImgProduct7,
} from "../../../assets/images";
import { getUri } from "../../../configs";
import Profile from "../Profile";

const Profiles = ({ variant, data, className }) => {
  const baseurl = getUri();

  return (
    <div className={`mt-40 mobile:mt-52 mobile:mx-3 ${className}`}>
      {!variant && (
        <>
          {data ? (
            <>
              {data.map((profile) => {
                return (
                  <Profile
                    key={profile.id + Math.random(9998, 10904)}
                    title={profile.title}
                    description={profile.description}
                    button={profile.action_text ? profile.action_text : false}
                    image={baseurl + "/images/" + profile.image}
                    variant={profile.reverse ? "reverse" : false}
                    tag={profile.tag}
                  />
                );
              })}
            </>
          ) : (
            <>
              <Profile title="..." description="..." button="..." image={"..."} tag="..." />
            </>
          )}
        </>
      )}
      {variant === "product" && (
        <>
          <Profile
            title="Aplikasi Point of Sale"
            description="
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct}
          />
          <Profile
            title="Pencatatan Penjualan"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct2}
            variant="reverse"
            className="mt-20"
          />
          <Profile
            title="Laporan Keuangan"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct3}
          />
          <Profile
            title="Inventaris"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct4}
            variant="reverse"
            className="mt-20"
          />
          <Profile
            title="QR Menu"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct5}
          />
          <Profile
            title="Kitchen Display (FnB)"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct6}
            variant="reverse"
            className="mt-20"
          />
          <Profile
            title="Pembayaran QR, Debit, Kredit"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur laboriosam ullam fugit illum excepturi sapiente, consequuntur veniam quisquam quasi ad incidunt aliquam blanditiis inventore."
            image={ImgProduct7}
          />
        </>
      )}
    </div>
  );
};

export default Profiles;
