import React from "react";
import { Container } from "../../Modules";
import Slider from "react-slick";
import { getUri } from "../../../configs";

const Brands = ({ data }) => {
  let settings = {
    infinite: true,
    autoplaySpeed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const baseurl = getUri();

  return (
    <div className="my-20">
      <Container>
        <h2 className="text-2xl font-semibold mobile:mx-3">Klien & Partner</h2>
        <div className="">
          {data === null ? (
            <Slider {...settings} className="mt-10 animate-pulse">
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
              <div
                alt=""
                className="transition-all rounded-full w-full h-24 bg-slate-400 duration-500 p-10 hover:p-0"
              />
            </Slider>
          ) : (
            <Slider {...settings} className="mt-10">
              {data.map((value) => {
                return (
                  <img
                    key={value.id + 12900 * Math.random(10,100)}
                    src={baseurl + "/images/" + value.image}
                    alt={value.description}
                    className="transition-all h-24 duration-500 grayscale hover:grayscale-0 p-2 hover:p-0"
                  />
                );
              })}
              
            </Slider>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Brands;
