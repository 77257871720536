const initialStateApi = {
  dataShowroomBanner: null,
  rowShowroomBanner: 0,
  dataShowroomProduct: null,
  rowShowroomProduct: 0,
  dataShowroomVideo: null,
  rowShowroomVideo: 0,
};

const showroomReducer = (state = initialStateApi, action) => {
  if (action.type === "SET_DATA_SHOWROOM_BANNER") {
    return {
      ...state,
      dataShowroomBanner: action.payload,
      rowShowroomBanner: action.row,
    };
  }

  if (action.type === "SET_DATA_SHOWROOM_VIDEO") {
    return {
      ...state,
      dataShowroomVideo: action.payload,
      rowShowroomVideo: action.row,
    };
  }

  if (action.type === "SET_DATA_SHOWROOM_PRODUCT") {
    return {
      ...state,
      dataShowroomProduct: action.payload,
      rowShowroomProduct: action.row,
    };
  }

  return state;
};

export default showroomReducer;
