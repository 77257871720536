import React from "react";
import { Button } from "../../Atoms";
import { Container } from "../../Modules";

const Profile = ({
  title,
  description,
  tag,
  button,
  image,
  variant,
  className,
  animate,
  classNameImg,
  classNameDiv,
  children,
}) => {
  const variantLeft = Array.isArray(animate) ? animate[0] : animate;
  const variantRight = Array.isArray(animate) ? animate[1] : animate;
  return (
    <Container
      className={`flex mobile:flex-wrap ${!variant && "flex-row-reverse"} mobile:flex-col-reverse ${className}`}
    >
      <div variant={variantLeft ? variantLeft : "fade-left"} className={`w-6/12 mobile:w-full ${classNameDiv}`}>
        {!children && (
          <>
            <h2 className="text-primary font-semibold text-2xl">
              {tag && (tag === "..." ? <div className="w-4/12 h-7 rounded bg-slate-400 animate-pulse"></div> : tag)}
            </h2>
            <h2 className="text-black tracking-wide leading-normal font-semibold text-2xl mobile:text-2xl my-4">
              {title === "..." ? (
                <div className="flex flex-wrap">
                  <div className="w-7/12 mt-2 mr-3 h-6 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-4/12 mt-2 mr-3 h-6 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-3/12 mt-2 mr-3 h-6 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-4/12 mt-2 mr-3 h-6 bg-slate-400 animate-pulse rounded"></div>
                </div>
              ) : (
                title
              )}
            </h2>
            <p className="text-lg mobile:text-">
              {description === "..." ? (
                <div className="flex flex-wrap">
                  <div className="w-5/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-2/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-4/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-3/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-4/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-4/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-3/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                  <div className="w-3/12 mt-2 mr-2 h-4 bg-slate-400 animate-pulse rounded"></div>
                </div>
              ) : (
                description
              )}
            </p>
            {button &&
              (button === "..." ? (
                <div className="w-4/12 h-10 rounded-full bg-slate-400 animate-pulse mt-10"></div>
              ) : (
                <Button variant="orange" className="my-10">
                  {button && button}
                </Button>
              ))}
          </>
        )}

        {children && children}
      </div>
      <div variant={variantRight ? variantRight : "fade-right"} className="w-6/12 mobile:w-full md:-mt-24 flex">
        {image === "..." ? (
          <div className="w-8/12 h-4/6 rounded bg-slate-400 animate-pulse m-auto mt-24"></div>
        ) : (
          <img src={image && image} alt="" className={classNameImg} />
        )}
      </div>
    </Container>
  );
};

export default Profile;
