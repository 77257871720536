import React from "react";

function Container({className, children, ...rest}) {
  return (
    <div className={`md:container md:mx-auto xl:px-16 ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default Container;
