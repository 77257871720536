import React from "react";
import {
  IconEmailRounded,
  IconFacebookDark,
  IconInstagramDark,
  IconTiktok,
  IconWhatsappDark,
} from "../../../assets/icons";

const Socmed = () => {
  function toLink(link) {
    window.open(link, "_blank");
  }

  return (
    <div className="space-x-3 mt-6">
      <button className="" type="button" onClick={() => toLink("https://wa.me/6281908192298")}>
        <img src={IconWhatsappDark} className="w-[40px]" alt="" />
      </button>
      <button className="" type="button" onClick={() => toLink("https://www.instagram.com/autopos.idn/")}>
        <img src={IconInstagramDark} className="w-[40px]" alt="" />
      </button>
      <button
        className=""
        type="button"
        onClick={() => toLink("https://www.facebook.com/Autopos-Indonesia-104519202186181/?ref=pages_you_manage")}
      >
        <img src={IconFacebookDark} className="w-[40px]" alt="" />
      </button>
      <button className="" type="button" onClick={() => toLink("https://www.tiktok.com/@autopos.idn")}>
        <img src={IconTiktok} className="w-[40px]" alt="" />
      </button>
      <button className="" type="button" onClick={() => toLink("mailto:autopos.id@gmail.com")}>
        <img src={IconEmailRounded} className="w-[40px]" alt="" />
      </button>
    </div>
  );
};

export default Socmed;
