const initialStateApi = {
  dataHomeBanner: null,
  rowHomeBanner : 0,
  dataHomeProfile: null,
  rowHomeProfile : 0,
  dataHomeBrand: null,
  rowHomeBrand : 0,
  dataHomePrice: null,
  rowHomePrice : 0,
};

const homeReducer = (state = initialStateApi, action) => {
  if (action.type === "SET_DATA_HOMEPAGE_BANNER") {
    return {
      ...state,
      dataHomeBanner: action.payload,
      rowHomeBanner: action.row,
    };
  }
  if (action.type === "SET_DATA_HOMEPAGE_PROFILE") {
    return {
      ...state,
      dataHomeProfile: action.payload,
      rowHomeProfile: action.row,
    };
  }
  if (action.type === "SET_DATA_HOMEPAGE_BRAND") {
    return {
      ...state,
      dataHomeBrand: action.payload,
      rowHomeBrand: action.row,
    };
  }
  if (action.type === "SET_DATA_HOMEPAGE_PRICING") {
    return {
      ...state,
      dataHomePrice: action.payload,
      rowHomePrice: action.row,
    };
  }

  return state;
};

export default homeReducer;
