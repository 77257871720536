import React, { useState } from "react";
import { Iframe, TabList } from "../../Atoms";
import Container from "../Container";
import { Icon } from "@iconify/react";

const Tabs = () => {
  const [ActiveTabs, setActiveTabs] = useState("FandB");
  const [ActiveContent, setActiveContent] = useState("FandB");
  const [Video, setVideo] = useState("k_0qmyVeX18");
  const [Title, setTitle] = useState("Food And Beverages");

  const setNavigationTab = (param, title) => {
    setActiveTabs(param);
    setActiveContent(param);
    setTitle(title);
  };

  const setVideoTab = (src) => {
    setVideo(src);
  };

  return (
    <div className="mt-40">
      <Container className="flex flex-col">
        <ul className="flex mx-auto ">
          <li
            className={`mx-3 px-1 cursor-pointer font-bold duration-200 transition-all flex align-middle ${
              ActiveTabs === "FandB" ? " border-b-[3px] border-primary opacity-100" : "opacity-50"
            }`}
            onClick={() => setNavigationTab("FandB", "Food And Beverages")}
          >
            F&B
          </li>
          <li
            className={`mx-3 px-1 cursor-pointer font-bold duration-200 transition-all flex align-middle ${
              ActiveTabs === "retail" ? " border-b-[3px] border-primary opacity-100" : "opacity-50"
            }`}
            onClick={() => setNavigationTab("retail", "Retail")}
          >
            Retail
          </li>
          <li
            className={`mx-3 px-1 cursor-pointer font-bold duration-200 transition-all flex align-middle ${
              ActiveTabs === "barbershop" ? " border-b-[3px] border-primary opacity-100" : "opacity-50"
            }`}
            onClick={() => setNavigationTab("barbershop", "Barbershop")}
          >
            Barbershop
          </li>
          <li
            className={`mx-3 px-1 cursor-pointer font-bold duration-200 transition-all flex align-middle ${
              ActiveTabs === "laundry" ? " border-b-[3px] border-primary opacity-100" : "opacity-50"
            }`}
            onClick={() => setNavigationTab("laundry", "Laundry")}
          >
            Laundry
          </li>
        </ul>

        <div className="items-start mt-10">
          <h1 className="text-3xl font-medium">{Title}</h1>

          <div className={`${ActiveContent === "FandB" ? "" : "hidden"} flex flex-wrap`}>
            <div className="mr-20 mobile:w-12/12 md:w-3/12">
              <ul className="mx-auto space-y-6 mt-6">
                <TabList
                  video={Video}
                  setVideoTab={setVideoTab}
                  src="k_0qmyVeX18"
                  title="Aplikasi POS"
                  icon="ic:round-point-of-sale"
                />
              </ul>
            </div>
            <div className="md:w-8/12 mobile:w-12/12">
              <div className="">
                <Iframe src={Video} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Tabs;
