import React from "react";
import { ImgMap } from "../../../assets/images";
import { Location } from "../../Atoms";
import { Container } from "../../Modules";

const Area = () => {
  return (
    <div className="my-16">
      <Container className="px-4">
        <h1 className="text-2xl font-semibold">Area Cakupan</h1>
        <div className="grid md:grid-cols-2">
          <div>
            <p className="text-lg py-6 tracking-wide">
              AutoPOS saat ini dapat mencakup beberapa area dan kota. Kami akan terus mengembangkan aplikasi dan
              memperluas area cakupan ke berbagai daerah di Indonesia.
            </p>
            <div className="grid md:grid-cols-2 gap-2">
            <img src={ImgMap} alt="" className="sm:hidden" />
              <Location name="Jakarta" />
              <Location name="Surabaya" />
              <Location name="Bandung" />
              <Location name="Bali" />
              <Location name="Bekasi" />
            </div>
          </div>
          <div className="p-6 mobile:hidden sm:inline-block">
            <img src={ImgMap} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Area;
