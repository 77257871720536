const initialStateApi = {
    dataAboutBanner: null,
    rowAboutBanner : 0,
    dataAboutProfile: null,
    rowAboutProfile : 0,
  };
  
  const aboutReducer = (state = initialStateApi, action) => {
    if (action.type === "SET_DATA_ABOUT_BANNER") {
      return {
        ...state,
        dataAboutBanner: action.payload,
        rowAboutBanner: action.row,
      };
    }
    if (action.type === "SET_DATA_ABOUT_PROFILE") {
      return {
        ...state,
        dataAboutProfile: action.payload,
        rowAboutProfile: action.row,
      };
    }
  
    return state;
  };
  
  export default aboutReducer;
  