/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getUri } from "../../../configs";
import { GetTestimonials } from "../../../configs/Redux/actions/testimonialAction";
import Testimonial from "../Testimonial";

const Testimonials = () => {
  const { data, row_data } = useSelector((state) => state.testimonialReducer);
  const dispatch = useDispatch();
  const baseurl = getUri();

  useEffect(() => {
    dispatch(GetTestimonials());
  }, [dispatch]);

  let settings = {
    infinite: true,
    autoplaySpeed: 2000,
    slidesToShow: 1.4,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    stagePadding: 150,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const sliderRef = useRef();
  return (
    <div className="py-28">
      <div className="mobile:mx-3 xl:ml-24">
        <h2 className="text-3xl font-semibold">Testimoni</h2>
        <div className="flex mobile:flex-col">
          <div className="w-2/12 mobile:w-full flex items-center">
            <div className="py-12 space-x-12">
              <button>
                <ArrowLeft size={46} className="text-primary" onClick={() => sliderRef.current.slickPrev()} />
              </button>
              <button>
                <ArrowRight size={46} className="text-primary" onClick={() => sliderRef.current.slickNext()} />
              </button>
            </div>
          </div>
          <Slider ref={sliderRef} {...settings} className="w-10/12 mobile:w-full flex shadow-custom -z-50">
            {row_data > 0 &&
              data.map((res) => {
                return (
                  <Testimonial
                    key={res.id + Math.random(10, 99) * 1200}
                    name={res.name}
                    status={res.major}
                    statement={res.description}
                    img={baseurl + "/images/" + res.image}
                  />
                );
              })}
            {row_data < 1 && <Testimonial />}
            {row_data < 1 && <Testimonial />}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
