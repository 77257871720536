import React from "react";
import { useNavigate } from "react-router-dom";
import { LogoWhite, PlayStore } from "../../../assets/images";
import { CheckLink } from "../../../configs";
import { Container } from "../../Modules";
import Socmed from "../Socmed";

const Footer = ({className, ...rest}) => {

  const history = useNavigate();
  return (
    <div className={"py-14 mt-24 bg-footer " + className}>
      <Container className="border-b-2 border-white py-20 2xl:mt-32 2xl:mb-10 mobile:mx-3">
        <div>
          <img src={LogoWhite} alt="" className="2xl:w-[170px]" />
        </div>
        <div className="flex mobile:flex-col mt-8 md:space-x-16 mobile:space-y-10 2xl:mb-10">
          <div className="text-white md:w-5/12 space-y-5">
            <h1 className="md:text-2xl 2xl:text-3xl mobile:text-xl font-semibold">PT Automation Business Indonesia</h1>
            <p className="md:text-lg mobile:text-md tracking-wider">
              Neo Soho Podomoro City Unit 39.19 <br /> Jl Letjen S. Parman Kav. 28, Tanjung Duren Selatan, Grogol
              Petamburan Jakarta Barat – Indonesia
            </p>
          </div>
          <div className="text-white mobile:w-full md:w-3/12">
            <h1 className="md:text-2xl mobile:text-xl font-semibold">Site Map</h1>
            <ul className="space-y-6 mt-6">
              <li>
                <a href={CheckLink(false)}>Produk</a>
              </li>
              <li>
                <a href={CheckLink(false)}>Hardware</a>
              </li>
              <li>
                <a className="cursor-pointer" onClick={() => history('/terms-of-privacy')}>Kebijakan Privasi</a>
              </li>
              <li>
                <a className="cursor-pointer" onClick={() => history('/terms-of-service')}>Ketentuan Layanan</a>
              </li>
            </ul>
          </div>
          <div className="text-white mobile:w-full md:w-4/12">
            <h1 className="md:text-2xl mobile:text-xl font-semibold">Sosial Media</h1>
            <Socmed/>
            <img src={PlayStore} alt="" className="my-8 cursor-pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.autopos.autoposapps')}/>
          </div>
        </div>
      </Container>
      <Container className="text-white mt-6 mobile:mx-3 2xl:mb-10">
        <h4 className="md:text-xl mobile:text-md"><span className="font-bold">&copy; 2022</span> PT. Automation Business Indonesia</h4>
      </Container>
    </div>
  );
};

export default Footer;
