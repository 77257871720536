import React from "react";
import { ImgContact, ImgContact2 } from "../../../assets/images";
import { Button } from "../../Atoms";
import { Container } from "../../Modules";

const Contact = ({ variant }) => {
  return (
    <div className="mt-32 md:bg-contact">
      {!variant && (
        <Container className="flex mobile:flex-col 2xl:gap-28">
          <div className="md:w-6/12 md:ml-14">
            <img src={ImgContact} alt="" />
          </div>
          <div
            variant="zoom-out"
            duration={3000}
            className="md:w-6/12 flex flex-col justify-center space-y-6 mobile:mx-3 mobile:my-6"
          >
            <h2 className="text-xl text-primary font-medium">Hubungi Kami</h2>
            <h2 className="text-3xl mobile:text-2xl font-semibold">Memiliki pertanyaan seputar aplikasi AutoPOS?</h2>
            <p className="text-lg mobile:text-md tracking-wider">Konsultasikan bisnis anda dengan kami</p>
            <div>
              <Button variant="orange">Hubungi Kami</Button>
            </div>
          </div>
        </Container>
      )}
      {variant && (
        <Container className="flex mobile:flex-col 2xl:gap-28">
          <div className="md:w-6/12 md:ml-14">
            <img src={ImgContact2} alt="" />
          </div>
          <div
            variant="zoom-out"
            duration={3000}
            className="md:w-6/12 flex flex-col justify-center space-y-6 mobile:mx-3 mobile:my-6"
          >
            <h2 className="text-3xl mobile:text-2xl font-semibold tracking-wider leading-10">Buat usaha Anda menjadi semakin mudah dengan menggunakan perangkat pendukung</h2>
            <div>
              <Button variant="orange">Hubungi Sales</Button>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Contact;
