const initialStateApi = {
    dataHardwareBanner: null,
    rowHardwareBanner : 0,
    dataHardwareProduct: null,
    rowHardwareProduct : 0
  };
  
  const hardwareReducer = (state = initialStateApi, action) => {
    if (action.type === "SET_DATA_HARDWARE_BANNER") {
      return {
        ...state,
        dataHardwareBanner: action.payload,
        rowHardwareBanner: action.row,
      };
    }
  
    if (action.type === "SET_DATA_HARDWARE_PRODUCT") {
      return {
        ...state,
        dataHardwareProduct: action.payload,
        rowHardwareProduct: action.row,
      };
    }
  
    return state;
  };
  
  export default hardwareReducer;
  