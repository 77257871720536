const initialStateApi = {
    dataProductBanner: null,
    rowProductBanner : 0,
    dataProductProfile: null,
    rowProductProfile : 0,
  };
  
  const productReducer = (state = initialStateApi, action) => {
    if (action.type === "SET_DATA_PRODUCT_BANNER") {
      return {
        ...state,
        dataProductBanner: action.payload,
        rowProductBanner: action.row,
      };
    }
    if (action.type === "SET_DATA_PRODUCT_PROFILE") {
      return {
        ...state,
        dataProductProfile: action.payload,
        rowProductProfile: action.row,
      };
    }
  
    return state;
  };
  
  export default productReducer;
  