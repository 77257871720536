import axios from "axios";
import { getUri } from "../../Helpers";

const key = process.env.REACT_APP_KEY;
const baseurl = getUri("api");

export const GetShowroomBanner = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl + "/q/showroom/banner", config)
    .then((response) => {
      const result = response.data;
      dispatch({ type: "SET_DATA_SHOWROOM_BANNER", payload: result.data, row: result.row_data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GetShowroomVideo = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl + "/q/showroom/video", config)
    .then((response) => {
      const result = response.data;
      dispatch({ type: "SET_DATA_SHOWROOM_VIDEO", payload: result.data, row: result.row_data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GetShowroomProduct = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl + "/q/showroom/product", config)
    .then((response) => {
      const result = response.data;
      dispatch({ type: "SET_DATA_SHOWROOM_PRODUCT", payload: result.data, row: result.row_data });
    })
    .catch((error) => {
      console.log(error);
    });
};
