/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { AlignRight, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/images";
import { Button, Navs } from "../../Atoms";
import { Container } from "../../Modules";

const Navbar = ({ variant }) => {
  const [toggle, setToggle] = useState(false);
  const navSideClass = toggle ? "right-0 transition-all duration-500" : "transition-all duration-500 -right-full";
  const navAttributeClass = toggle ? "-left-full absolute" : "transition-all duration-500 left-0";
  const close = toggle ? "left-0 transition-all" : "-right-full fixed";

  const menus = [
    {
      label: "Produk",
      link: "product",
    },
    "Hardware",
    "Showroom",
    {
      label: "Lainnya",
      subMenus: [
        {
          label: "Tentang",
          link: "about-us",
        },
        {
          label: "Hubungi Kami",
          link: "contact-us",
        },
        {
          label: "Kebijakan Privasi",
          link: "terms-of-privacy",
        },
        {
          label: "Ketentuan Layanan ",
          link: "terms-of-service",
        },
      ],
    },
  ];

  const variants = {
    default: "bg-white",
    gradient: "bg-gradient-to-r from-transparent to-semifullcolor",
    // gradient: "bg-semicolor",
    dark: "bg-gradient-to-r from-transparent via-gray-200 to-gray-600",
  };

  const pickedVariant = variants[variant ? variant : "default"];
  const history = useNavigate();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const styleStatic = offset > 100 ? " via-white top-0 sticky md:backdrop-blur-md lg:bg-opacity-50 z-50" : "";

  return (
    <nav className={`${pickedVariant} shadow-md ${styleStatic}`}>
      <Container className="flex py-5 ">
        <div className={`w-10/12 md:w-3/12 mr-10 ${navAttributeClass}`}>
          <img
            src={Logo}
            alt="Logo Autopos"
            className="w-9/12 my-auto ml-2 cursor-pointer"
            onClick={() => history("/")}
          />
        </div>
        <div className="w-7/12 hidden md:flex">
          <Navs menus={menus} />
        </div>
        <div className="w-3/12 space-x-3 md:flex hidden justify-end">
          <Button
            label="Login"
            variant="orange-text"
            onClick={() => window.open("http://my.autopos.id/login", "_self")}
          />
          <Button label="Coba Gratis" variant="orange" />
        </div>

        <div className={`w-2/12 my-auto mr-3 sm:hidden ${navAttributeClass}`}>
          <AlignRight
            className="ml-auto mr-3 cursor-pointer"
            onClick={() => {
              setToggle(true);
            }}
          />
        </div>

        {/* MOBILE VERSION */}
        <div className={`w-2/12 lg:hidden my-auto py-1 ${close}`}>
          <X className="ml-auto mr-3 cursor-pointer" size={26} onClick={() => setToggle(false)} />
        </div>

        <div className={`fixed top-0 lg:hidden bg-white h-screen w-9/12 ${navSideClass}`}>
          <Navs menus={menus} />
          <div className="w-full mx-2 my-5">
            <Button
              label="Login"
              variant="orange-text"
              onClick={() => window.open("http://my.autopos.id/login", "_self")}
            />
            <Button label="Coba Gratis" variant="orange" />
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
