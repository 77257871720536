import React from 'react'
import { Advantages, Brands, Footer, HeroProduct, Navbar, ProductDetails } from '../../components/Molecules'

const DetailPage = () => {
  
  return (
    <div>
        <Navbar/>
        <HeroProduct/>
        <Brands/>
        <Advantages/>
        <ProductDetails/>
        <Footer/>
    </div>
  )
}

export default DetailPage