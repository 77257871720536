export const getUri = (param) => {
  let uri = "http://127.0.0.1";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    uri = process.env.REACT_APP_BASEURL_DEV;
  } else {
    uri = process.env.REACT_APP_BASEURL_PROD;
  }

  if(param === 'api'){
    uri = uri + process.env.REACT_APP_API_SEGMENT;
  }

  return uri;
};
