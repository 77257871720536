import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckLink } from "../../../configs";
import Nav from "../Nav";

const Navs = ({ menus }) => {
  let key = 111;
  const path = window.location.href.split('#')[1];

  const history = useNavigate();
  return (
    <>
      {Array.isArray(menus) && (
        <ul className="md:flex md:space-x-14 my-auto font-semibold">
          {menus.map((menu) => {
            return (
              <li
                key={menu.id ? menu.id : key++}
                className="mobile:border-b-[1px]  transition-all duration-300 mobile:border-gray-300"
              >
                {typeof menu === "object" && <Nav config={menu} keys={menu.id + key++} path={path} />}
                {typeof menu !== "object" && (
                  <> 
                    <span
                      onClick={() => history('/'+CheckLink(menu))}
                      className={`mobile:p-5 mobile:py-5 mobile:flex w-full mobile:hover:bg-gray-100 hover:text-primary mobile:hover:transition-all mobile:hover:duration-300 cursor-pointer ${path === '/' + CheckLink(menu) ? 'text-primary' : ''}`}
                    >
                      {menu}
                    </span>
                  </> 
                )} 
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Navs;
