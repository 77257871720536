import React, { useState } from "react";
import { Facebook, Instagram, Twitter } from "react-feather";
import { IconWhatsapp } from "../../assets/icons";
import { Button, Input } from "../../components";

const Coba = () => {
  const [check, setCheck] = useState(false);
  const styleListSosmed =
    "flex cursor-pointer p-1 bg-white text-primary rounded-lg text-xs items-center space-x-2 transition-all duration-500 hover:text-white hover:bg-primary hover:p-2 hover:border-2 hover:border hover:border-white border-solid";
  return (
    <div className="bg-semilight bottom-0 right-0 fixed mr-6 mb-6 z-40">
      <div
        className={`bg-white shadow-custom md:w-1/5 mobile:w-8/12 sm:w-8/12 right-6 fixed bottom-0 rounded-lg transition-all duration-500 ${
          check ? "visible transform opacity-100 -translate-y-20" : "invisible opacity-0"
        }`}
      >
        <div className="bg-primary p-5 space-y-3 rounded-t-lg">
          <span className={styleListSosmed}>
            <Facebook size={18} /> <b>Autopos Indonesia</b>
          </span>
          <span className={styleListSosmed}>
            <Instagram size={18} /> <b>Autopos Indonesia</b>
          </span>
          <span className={styleListSosmed}>
            <img src={IconWhatsapp} alt="whatsapp" className="h-[18px]" /> <b>Autopos Indonesia</b>
          </span>
          <span className={styleListSosmed}>
            <Twitter size={18} /> <b>Autopos Indonesia</b>
          </span>
        </div>
        <div className="p-5">
          <h1 className="text-lg font-semibold mb-4">Kontak Kami</h1>
          <form action="" className="space-y-3">
            <Input label="Email" name="email" placeholder="xxxx@domain.com" type="email" />
            <Input label="Pesan" name="description" id="description" type="textarea" />
            <Button label="Submit" variant="orange" className="w-full" />
          </form>
        </div>
      </div>
      <button
        onClick={() => setCheck(check ? false : true)}
        title="Contact Sale"
        class="fixed z-90 bottom-3 right-4 bg-primary w-16 h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-alternatif hover:drop-shadow-2x pR-auto"
      >
        &#9993;
      </button>
    </div>
  );
};

export default Coba;
