import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { AboutPage, ContactPage, HardwarePage, HomePage, LoginPage, PrivacyPage, ProductPage, RegisterPage, ServicePage } from "../../pages";
import DetailPage from "../../pages/DetailPage";
import ShowroomPage from "../../pages/ShowroomPage";

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/product/detail" element={<DetailPage />} />
        <Route path="/autopos-manager" element={<ProductPage />} />
        <Route path="/autopos" element={<ProductPage />} />
        <Route path="/kds" element={<ProductPage />} />
        <Route path="/showroom" element={<ShowroomPage />} />
        <Route path="/hardware" element={<HardwarePage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/terms-of-privacy" element={<PrivacyPage />} />
        <Route path="/terms-of-service" element={<ServicePage />} />
        <Route path="/auth">
          <Route path="login" element={<LoginPage  />} />
          <Route path="register" element={<RegisterPage  />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default Router;
