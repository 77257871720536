import React, { useState } from "react";
import { PlusSquare } from "react-feather";

const Advantage = ({ variant, title, description, icon }) => {
  const variants = {
    primary: "bg-primary text-white hover:p-12 hover:shadow-2xl hover:bg-white hover:text-black transition-all duration-500",
    secondary: "bg-white text-dark shadow-custom hover:bg-primary hover:p-12 transition-all duration-500 hover:text-white",
  };

  const [iconEvent, setIconEvent] = useState(false)

  const classIcon = iconEvent ? 'rotate-90' : "-rotate-90";

  const styleVariant = variants[variant ? variant : 'secondary'];
  return (
    <div className={`flex flex-col items-center text-center rounded-xl p-10 ${styleVariant}`} onMouseOver={() => setIconEvent(true)} onMouseOut={() => setIconEvent(false)}>
      {icon ? icon : <PlusSquare size={60} className={` transition-all duration-500 ${classIcon}`} />}
      <h3 className="font-semibold text-3xl mobile:text-xl mt-5 mb-3">{title}</h3>
      <h5 className="mobile:text-sm">{description}</h5>
    </div>
  );
};

export default Advantage;
