/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button } from "../../Atoms";
import { Container } from "../../Modules";

const Hero = ({ variant, button, title, description, img, logo }) => {
  const variants = {
    primary: `md:bg-hero-1 mobile:bg-wallpaper`,
    normal: "md:bg-hero-normal mobile:bg-wallpaper ",
    secondary: `md:bg-hero-2 mobile:bg-primary `,
    dark: "md:bg-hero-3 lg:bg-dark-custom mobile:bg-hero-3 sm:bg-hero-3 xs:bg-hero-3",
    alternatif: "md:bg-hero-4 mobile:bg-hero-4",
  };

  let pickedVariant = variants[variant ? variant : "primary"];

  return (
    <div className={`${img ? pickedVariant : "bg-wallpaper"} md:h-full 2xl:h-auto flex bg-hero 2xl:py-10`}>
      <Container className="flex mobile:flex-wrap mobile:flex-col-reverse h-full 2xl:space-x-24">
        <div
          className={`${
            variant !== "dark" || variant !== "normal" ? "md:w-5/12" : "md:w-7/12"
          } w-12/12 mx-5 mobile:-mt-10 md:mx-0  md:mt-40 ${variant !== "text-primary" ? "text-white" : ""}`}
        >
          {!variant && (
            <>
              <h2 className="md:text-5xl text-3xl font-semibold text-dark">
                {!title ? (
                  <div className="flex flex-wrap -mt-6 animate-pulse">
                    <div className="w-7/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                    <div className="w-4/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                    <div className="w-3/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                    <div className="w-8/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  </div>
                ) : (
                  <>
                    {title[0]} <span className="text-primary">{title[1]}</span> {title[2]}
                  </>
                )}
              </h2>
              <p className="py-4 text-xl text-dark">
                {!description ? (
                  <div className="flex flex-wrap animate-pulse">
                    <div className="w-full h-7 mobile:h-5 bg-slate-400 rounded-full"></div>
                    <div className="w-1/2 mt-3 h-7 mobile:h-5 bg-slate-400 rounded-full"></div>
                  </div>
                ) : (
                  <>{description}</>
                )}
              </p>
            </>
          )}
          {variant === "alternatif" && (
            <>
              {title === null ? (
                <div className="flex flex-wrap -mt-6 animate-pulse">
                  <div className="w-7/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-4/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-3/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-8/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                </div>
              ) : (
                <h2 className="2xl:text-5xl lg:text-4xl text-3xl md:my-12 font-semibold text-dark tracking-wide mobile:mb-16 leading-10">
                  Bergabunglah Dengan Kami Untuk Membuat Bisnis Anda Menjadi <br />
                  <span className="text-primary">Lebih Mudah</span>
                </h2>
              )}
            </>
          )}
          {variant === "normal" && (
            <>
              {title === null ? (
                <div className="flex flex-wrap -mt-6 animate-pulse">
                  <div className="w-7/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-4/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-3/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-8/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                </div>
              ) : (
                <h2 className="2xl:text-[2.32em] lg:text-[2.32em] text-2xl mt-5 md:my-12 font-semibold text-dark tracking-wider mobile:mb-16 xl:leading-[3rem] mobile:leading-7">
                  {title[0]}
                  <br />
                  <span className="text-primary">{title[1]}</span>
                </h2>
              )}
            </>
          )}
          {variant !== "normal" && variant !== "alternatif" && variant ? (
            <>
              {!title ? (
                <div className="flex flex-wrap -mt-6 animate-pulse">
                  <div className="w-7/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-4/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-3/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                  <div className="w-8/12 mr-3 mt-3 mobile:h-8 md:h-12 rounded-full bg-slate-400"></div>
                </div>
              ) : (
                <h2 className="md:text-4xl text-3xl md:mt-16 font-semibold">{title}</h2>
              )}
              <p className="py-4 text-xl">{description}</p>
              {logo ? (
                logo === "skeleton" ? (
                  <div className="w-3/4 h-12 bg-slate-400 rounded-full animate-pulse"></div>
                ) : (
                  <img src={logo} alt="Logo Hero" className="mb-40" />
                )
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="space-x-2 pt-5">
            {!Array.isArray(button) && button && button !== "nullable" ? (
              <Button label={button} variant="orange" />
            ) : (
              <></>
            )}
            {Array.isArray(button) && button.length > 1 ? (
              button === "nullable" ? (
                <></>
              ) : (
                <>
                  <Button label={button[0]} variant="orange" />
                  <Button label={button[1]} variant="orange-outline" />
                </>
              )
            ) : button === "nullable" ? (
              <></>
            ) : (
              <div className="flex space-x-4">
                <div className="animate-pulse w-36 h-10 rounded-full bg-slate-300"></div>
                <div className="animate-pulse w-36 h-10 rounded-full bg-slate-300"></div>
              </div>
            )}
          </div>
        </div>
        {variant !== "alternatif" && (
          <div
            className={`${variant === "normal" ? "md:w-10/12 lg:w-8/12 xl:7/12" : "md:w-9/12"} w-full mobile:mb-14${
              variant !== "primary" ? " mobile:my-12" : "mobile:-mt-4 "
            }`}
          >
            {img !== null ? (
              <img
                src={img}
                alt="Gambar Hero"
                className={`${!variant ? "md:mt-16 pb-16 md:h-auto" : "md:-mt-6 md:h-[700px]"} ${
                  variant === "dark" ? "lg:hidden md:flex mobile:flex sm:flex xs:flex" : ""
                }`}
              />
            ) : (
              <div className="animate-pulse mobile:w-full ml-24 h-80 w-8/12 mt-20 mobile:mt-0 mobile:h-60 bg-slate-400 mobile:ml-4 mb-5 rounded"></div>
            )}
          </div>
        )}
        {variant === "alternatif" && (
          <div className={`md:w-7/12 md:pb-24 w-full mobile:mb-14 md:-mr-24 mobile:my-12`}>
            <img src={img} alt="Gambar Hero" className="md:px-24 py-16 mobile:px-10" />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Hero;
