import React, { useState } from "react";

const Input = ({ label, type }) => {
  const [animateText, setAnimateText] = useState("default");

  const labelsClass = {
    default: "pt-[12px] text-secondary -z-10",
    focus: "-translate-y-3 bg-white transition-all duration-300 text-primary",
    hidden: "-translate-y-3 bg-white transition-all duration-300 text-secondary",
  };

  let labelClass = labelsClass[animateText];

  function checkValue(val) {
    if (val) {
      setAnimateText("hidden");
    } else {
      setAnimateText("default");
    }
  }

  return (
    <div className="w-full">
      <h1 className={`absolute ml-4 ${labelClass}`}>{label}</h1>
      {type === 'textarea' && (
        <textarea
        type={type ? type : "text"}
        className={`py-3 px-5 outline-none focus:border-primary border-[1.8px] border-secondary focus:placeholder:text-transparent w-full bg-transparent font-medium text-sm ${
          animateText === "hidden" ? "text-secondary" : ""
        }`}
        onFocus={() => setAnimateText("focus")}
        onBlur={(e) => checkValue(e.target.value)}
      ></textarea>
      )}
      {
        type !== 'textarea' && (
          <input
            type={type ? type : "text"}
            className={`py-3 px-5 outline-none focus:border-primary border-[1.8px] border-secondary focus:placeholder:text-transparent w-full bg-transparent font-medium text-sm ${
              animateText === "hidden" ? "text-secondary" : ""
            }`}
            onFocus={() => setAnimateText("focus")}
            onBlur={(e) => checkValue(e.target.value)}
          />
        )
      }
    </div>
  );
};

export default Input;
