import axios from "axios";
import { getUri } from "../../Helpers";

const key = process.env.REACT_APP_KEY;
const baseurl = getUri("api");

export const GetHardwareBanner = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl+ "/q/hardware/banner", config)
    .then((response) => {
      const result = response.data;
      dispatch({type : 'SET_DATA_HARDWARE_BANNER', payload:  result.data, row: result.row_data});
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GetHardwareProduct = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl+ "/q/hardware/product", config)
    .then((response) => {
      const result = response.data;
      dispatch({type : 'SET_DATA_HARDWARE_PRODUCT', payload:  result.data, row: result.row_data});
    })
    .catch((error) => {
      console.log(error);
    });
};