import React from "react";
import { Button } from "../../Atoms";
import { Card } from "../../Modules";
import { IconStar } from "../../../assets/icons";

const Pricing = ({ price, description, tag, features, button, variant }) => {
  return (
    <div>
      {variant === "skeleton" ? (
        <Card variant={"pricing"} className=" opacity-75">
          <div className="space-y-4 animate-pulse" id="header-card">
            <h4 className="h-7 w-4/12 rounded flex justify-between bg-slate-400"> </h4>
            <h2 className="h-12 w-7/12 rounded flex justify-between bg-slate-400"> </h2>
            <p className="h-5 w-8/12 rounded bg-slate-300"> </p>
            <hr className="-mx-2 border-[1px]" />
            <div id="body-card" className="space-y-4">
              <h4 className="h-7 w-6/12 rounded flex justify-between bg-slate-400 mb-6"> </h4>
              <p className="h-5 w-8/12 rounded bg-slate-300"> </p>
              <p className="h-5 w-4/12 rounded bg-slate-300"> </p>
              <p className="h-5 w-9/12 rounded bg-slate-300"> </p>
              <p className="h-5 w-10/12 rounded bg-slate-300"> </p>
              <p className="h-5 w-5/12 rounded bg-slate-300"> </p>
            </div>
          </div>
          <div className="w-full h-10 rounded-full bg-slate-400 animate-pulse"></div>
        </Card>
      ) : (
        <Card variant={variant ? variant : "pricing"}>
          <div className="space-y-4" id="header-card">
            <h4 className="text-primary font-semibold text-xl flex justify-between">
              {tag}
              {variant === "pricing-active" && <img src={IconStar} alt="Icon Star Premium" />}
            </h4>
            <h2 className="font-semibold text-4xl ">Rp. {price}</h2>
            <p className="text-lg">{description}</p>
            <hr className="-mx-2 border-[1px]" />
            <div id="body-card" className="space-y-4">
              <h4 className="text-alternatif font-medium text-lg">Fitur-fitur</h4>
              <ul className="space-y-3">
                {Array.isArray(features) &&
                  features.map((feature) => {
                    return (
                      <li key={feature} className="font-medium text-lg">
                        {feature}
                      </li>
                    );
                  })}
                {!Array.isArray(features) && <li className="font-medium text-lg">{features}</li>}
              </ul>
            </div>
          </div>
          <Button
            variant="orange"
            className="w-full transition-all duration-500 hover:bg-white hover:text-primary hover:border-[1px] border-primary mt-16"
          >
            {button ? button : "Coba Gratis"}
          </Button>
        </Card>
      )}
    </div>
  );
};

export default Pricing;
