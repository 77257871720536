import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconArrowFilledDown, IconArrowFilledDownActive } from "../../../assets/icons";
import { CheckLink } from "../../../configs";

const Nav = ({ config, keys, path }) => {
  const [ dropdown, setDropdown ] = useState(false);
  const dropdownClass = dropdown ? "md:top-20 md:mt-1 md:rounded-xl md:absolute h-auto visible translate-y-0 transform" : "invisible -translate-y-10 absolute h-[0px]";
  let i = 1;

  const history = useNavigate();

  const [ActiveIcon, setActiveIcon] = useState(false)

  const setDropdownEvent = (d) =>{
    setDropdown(d ? false : true);
    setActiveIcon(d ? true : false)
    console.log(ActiveIcon);
  }

  const setIconRef = (bool) => {
    const res = dropdown ? true : false;
    setActiveIcon(res ? true : bool)
  }

  return (
    <>
      {config.subMenus && (
        <div>
          <span
            className={`mobile:p-5 flex mobile:hover:bg-gray-100 mobile:hover:transition-all mobile:hover:duration-300 hover:text-primary cursor-pointer ${path === `/${CheckLink(config)}` ? 'text-primary' : ''} ${ActiveIcon ? 'text-primary' : ''}`}
            onClick={() => setDropdownEvent(dropdown)} onMouseOver={() => setIconRef(true)} onMouseOut={() => setIconRef(false)}
          >
            {config.label}
            <img src={ActiveIcon ? IconArrowFilledDownActive : IconArrowFilledDown} alt="img icon filled arrow down" className="ml-2 my-auto" size={20} />
          </span>
          <div className={`mobile:border-t-[1px] md:shadow-custom mobile:border-gray-300 pr-10  transition-all duration-500 ${dropdownClass} bg-white z-40`}>
            <ul className={`space-y-4 p-4 ${dropdown ? 'block' : 'hidden'}`} >
              {config.subMenus.map((submenu) => {
                return (
                  <div key={Math.floor(Math.random() * 10000) + 1}
                  data-id={Math.floor(Math.random() * 10000) + 1}>
                    <li>
                      <span
                        onClick={() => history('/' + CheckLink(submenu) )}
                        className="hover:text-primary text-black hover:ml-3 transition-all duration-300 cursor-pointer"
                      >
                        {submenu.label}
                      </span>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {!config.subMenus && (
        <>
          <span
            className={`mobile:p-5 mobile:py-5 mobile:flex w-full mobile:hover:bg-gray-100 mobile:hover:transition-all hover:text-primary mobile:hover:duration-300 cursor-pointer ${path === `/${CheckLink(config)}` ? 'text-primary' : ''}`}
            onClick={() => history('/' + CheckLink(config) )}
          >
            {config.label}
          </span>
        </>
      )}
    </>
  );
};

export default Nav;
