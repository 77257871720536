import React from "react";
import { MapPin } from "react-feather";

const Location = ({name, className, ...rest}) => {
  return (
    <div className={`flex space-x-6 ${className}`} {...rest}>
      <MapPin className="font-semibold text-primary" />
      <h2 className="text-lg font-semibold">{name}</h2>
    </div>
  );
};

export default Location;
