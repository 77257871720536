import React from "react";

const Button = ({ variant, label, className, children, ...rest }) => {
  const variants = {
    orange: "bg-primary rounded-full text-white",
    "orange-hover": "hover:bg-primary rounded-full hover:text-white transition-all duration-500",
    "orange-text": "hover:text-primary transition-all duration-200",
    "orange-outline": "hover:bg-primary rounded-full text-primary border-[1px] border-primary hover:text-white transition-all duration-500",
    "orange-gradient": "bg-gradient-to-r from-intercolor via-intercolor to-primary rounded-full text-white",
  };
  const pickedVariant = variants[variant];

  return (
    <button className={`font-semibold py-2 px-6 ${pickedVariant} ${className}`} {...rest}>
      {label ? label : children}
    </button>
  );
};

export default Button;
