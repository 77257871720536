import React, { useEffect } from 'react'
import { Footer, Navbar } from '../../../components'
import MainPrivacy from '../../../components/Molecules/MainPrivacy'

const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar/>
      <MainPrivacy variant="service" title="Our Terms & Services"/>
      <Footer className="mt-48"/>
    </div>
  )
}

export default ServicePage