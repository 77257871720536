import React from "react";

const Iframe = ({ src, width, height, title, className, ...props }) => {
  return (
    <div>
      <iframe
        width={width ? width : 780}
        height={height ? height : 420}
        src={`https://www.youtube.com/embed/${src}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title ? title : "tidak ada title"}
        className="rounded-xl md:flex mobile:hidden sm:hidden xs:hidden"
        {...props}
      />
      <iframe
        src={`https://www.youtube.com/embed/${src}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title ? title : "tidak ada title"}
        className="rounded-xl md:hidden mobile:flex sm:flex xs:flex"
        {...props}
      />
    </div>
  );
};

export default Iframe;
