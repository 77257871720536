import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Brands, Contact, Footer, Hero, Navbar, Pricings, Profiles, Testimonials } from "../../components";
import { getUri } from "../../configs";
import { GetHomepageBanner, GetHomepageBrands, GetHomepagePrice, GetHomepageProfiles } from "../../configs/Redux/actions/homeAction";

const HomePage = () => {
  const { dataHomeBanner, rowHomeBanner, dataHomeProfile, rowHomeProfile, dataHomeBrand, rowHomeBrand, dataHomePrice, rowHomePrice } = useSelector((state) => state.homeReducer);
  const dispatch = useDispatch();
  const baseurl = getUri();

  useEffect(() => {
    dispatch(GetHomepageBanner());
    dispatch(GetHomepageProfiles());
    dispatch(GetHomepageBrands());
    dispatch(GetHomepagePrice());
  }, [dispatch]);

  let button = rowHomeBanner < 1 ? ["null"] : dataHomeBanner.additional.split(",");
  let title = rowHomeBanner < 1 ? null : dataHomeBanner.title.split("~");
  let description = rowHomeBanner < 1 ? null : dataHomeBanner.description;
  let image = rowHomeBanner < 1 ? null : baseurl + "/images/" + dataHomeBanner.image;

  return (
    <div className="lg:bg-wallpaper xl:bg-transparent">
      <Navbar />
      <main className="">
        <Hero button={button} img={image} title={title} description={description} />
        <Profiles data={rowHomeProfile > 0 ? dataHomeProfile : null} />
        <Brands data={rowHomeBrand > 0 ? dataHomeBrand : null} />
        <Pricings data={rowHomePrice > 0 ? dataHomePrice : null} />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
