import React from "react";

const Card = ({ variant, className, children, ...rest }) => {
  const variants = {
    "pricing" : "bg-white mx-2 p-6 rounded-2xl flex flex-col justify-between h-[700px] shadow",
    "pricing-active" : "bg-white mx-2 p-6 rounded-2xl flex flex-col justify-between h-[700px] shadow border-[1px] border-primary",
    "testimonial" : "bg-white p-6 flex rounded-lg shadow-custom"
  }
  const pickedVariant = variants[variant];

  return (
    <div className={`${pickedVariant} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default Card;
