import axios from "axios";
import { getUri } from "../../Helpers";

const key = process.env.REACT_APP_KEY;
const baseurl = getUri("api");

export const GetAboutBanner = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl + "/q/about/banner", config)
    .then((response) => {
      const result = response.data;
      dispatch({type : 'SET_DATA_ABOUT_BANNER', payload:  result.data, row: result.row_data});
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GetAboutProfile = () => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${key}` },
  };
  axios
    .get(baseurl + "/q/about/profile", config)
    .then((response) => {
      const result = response.data;
      dispatch({type : 'SET_DATA_ABOUT_PROFILE', payload:  result.data, row: result.row_data});
    })
    .catch((error) => {
      console.log(error);
    });
};