import React from "react";
import { ImgItem, ImgItem2 } from "../../../assets/images";
import { Container } from "../../Modules";
import Profile from "../Profile";

const ProductDetails = () => {
  return (
    <div className="mobile:mx-5">
      <Container>
        <div className="text-center">
          <h4 className="font-medium text-5xl mobile:text-3xl">Get Ready to Be Surprised</h4>
          <p className="md:text-xl mobile:text-md text-gray-400 pt-3 md:pb-20 md:px-32">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam quam dolorum quae porro voluptatibus
            dignissimos qui necessitatibus soluta sequi beatae!
          </p>
        </div>
        <Profile
          image={ImgItem}
          variant="reverse"
          className="mt-20"
          classNameImg="m-5 md:w-full"
          classNameDiv="pt-10"
        >
          <h1 className="text-5xl mobile:text-3xl font-semibold text-dark opacity-80">More productivity with less effort</h1>
          <p className="text-lg mobile:text-md mt-5 text-gray-600">
            Quaerat sodales sapien euismod blandit purus a purus ipsum primis in cubilia laoreet augue luctus magna
            dolor luctus and egestas sapien egestas vitae nemo volute{" "}
          </p>
          <h5 className="my-4 text-3xl mobile:text-xl font-semibold text-dark opacity-90">All Tools in 1 App</h5>
          <ol className="list-disc ml-4 text-gray-600 text-lg mobile:text-sm space-y-3">
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
          </ol>
        </Profile>
        <Profile
          image={ImgItem2}
          className="mt-32"
          classNameImg="m-5 md:w-full"
          classNameDiv="pt-10"
        >
          <h1 className="text-5xl mobile:text-3xl font-semibold text-dark opacity-80">More productivity with less effort</h1>
          <p className="text-lg mobile:text-md mt-5 text-gray-600">
            Quaerat sodales sapien euismod blandit purus a purus ipsum primis in cubilia laoreet augue luctus magna
            dolor luctus and egestas sapien egestas vitae nemo volute{" "}
          </p>
          <h5 className="my-4 text-3xl mobile:text-xl font-semibold text-dark opacity-90">All Tools in 1 App</h5>
          <ol className="list-disc ml-4 text-gray-600 text-lg mobile:text-sm space-y-3">
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, minima.</li>
          </ol>
        </Profile>
      </Container>
    </div>
  );
};

export default ProductDetails;
